import React, { useContext } from "react";
import backgroundImage from "./themeimg/dtLite/page_bg_image.jpg"
import headerImg from "./themeimg/dtLite/dream_travel_logo.png"
import banner from "./themeimg/dtLite/Banner4.jpg"
import { baseUrl, data, headers } from '../config/config';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { SendGuiDataEvents } from "../CommonScript";
import LoaderComponent from './Loader';
import { Context } from "../Context/Context";

const Activate = ()=>{
    const msisdn = localStorage.getItem("msisdn") ? localStorage.getItem("msisdn") : localStorage.getItem("msisdn2")
    const navigate = useNavigate()
    const {pageLoader , handlePageLoader, changeErrorState} = useContext(Context)
    const checkSubHandler = async (msisdn) => {
        handlePageLoader(true)
        try {
          const resp = await axios({
            method: 'post',
            url: baseUrl + 'checkSub',
            data: { msisdn: msisdn },
            headers: headers,
          });
      
          const currentStatus = resp.data.subStatus;
          if (resp.data.status !== '' && resp.data.status !== undefined) {
            if (resp.data.status === 'failed') {
                navigate('/thanks', {
                    state: { message: resp?.data?.message}
                  });
            } else if (resp.data.status === 'success') {
               if (resp.data.subStatus === 'active') {
                localStorage.setItem("msisdn", resp.data.msisdn);
                localStorage.setItem("status", resp.data.status);
                localStorage.setItem("subStatus", resp.data.subStatus);
      
                let guiEvents = {
                  page: "subscription",
                  status: currentStatus,
                  event: "subscribed_user",
                  status: currentStatus
                };
                await SendGuiDataEvents(guiEvents);
                navigate("/home")
              } 
             else if( resp.data.subStatus=== "new"){
                  navigate("/pack")
              }
              else{
                navigate('/thanks', {
                    state: { message: resp?.data?.message}
                  });
              }
            }
          }
      
          
          localStorage.setItem("subStatus", resp.data.subStatus);
          handlePageLoader(false)
        } catch (error) {
          console.error('An error occurred while checksub:', error);
        }
      }
    return(
        <div className="max-w-[500px] mx-auto h-[100vh] " 
             style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                {pageLoader && <LoaderComponent />}
            <div><img className="w-[65%] pt-2 mx-auto" src={headerImg} alt="header" /></div>
            <div className="theme-bg bg-cover bg-no-repeat">
                <div className="w-full mt-6 bg-[#F3F3F3]">
                    <img src={banner} className="h-[40vh] w-[98%] mx-auto rounded-xl"></img>
                </div>
                <div className="w-[80%] mx-auto text-black text-xl font-semibold text-center mt-20 tracking-wide">
                    Press Ok to Activate the service
                </div>
                <div className="mt-16">
                    <button className="button mx-auto is-warning is-fullwidth m-t-10" onClick={()=>checkSubHandler(msisdn)}>ok</button>
                </div>
            </div>
        </div>
    )
}

export default Activate