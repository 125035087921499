import React, { useEffect, useState } from 'react'
import { baseUrl, data, headers } from '../config/config';
import {useNavigate,useLocation} from 'react-router-dom';
import {Link} from 'react-router-dom';
import './style/loginotp.css'
import LoaderComponent from './Loader';
import axios from 'axios';



function Otp() {

  const [otpState , setOtpState] = useState()

  const navigate = useNavigate()

  const {state} = useLocation()


  console.log(state)

  const validateOtp = (pin) => {
    axios({
      url: baseUrl+'/activate',
      method:'post',
      data: {"msisdn": String(state.data) , "pin": String(otpState)},
      headers: headers
    }).then((resp) => {
      if(resp.data.status === 'failed'){
        console.log("api failed")
      }else if(resp.data.status === 'success'){
        console.log("api successful")
        navigate('/home')
        // store uid in localstorage
        localStorage.setItem("msisdn",state.data)
      }
    })
  }



  return (
    <>
      {(true) ?
        <div>
          <div>
            <div className="container">
              <div className="background-theme">
                <div className='contest-container '>
                  <div className='upper-yellow-bg '>
                    <div className='back-arrow back-btn-quiz' >
                      <div className='back-arrow-button back-btn-align '>
                        <p className="arrow left text-black"></p>
                      </div>
                    </div>
                    <h5 className='has-text-black has-text-centered '>DREAM TRAVEL</h5>

                  </div>
                  <div className='bottom-white-bg bg-quizBg bg-cover bg-no-repeat relative pt-40'>
                    <div className='card card-height  '>
                      <div className='card-container has-text-centered  '>
                        <div className='  text-4xl font-bold text-[#607AF1] '>
                          Get Ready for Fun!
                        </div>

                        <div className=' '>
                          The best way to navigate your world
                          and discover new places play the contest
                          and get a chance to win free trip every month
                        </div>

                        <div className='  mt-8 border-2 border-gray-200'>
                          <input className='py-4 outline-none text-center' type="number" value={otpState} onChange={(e) => setOtpState(e.target.value)} placeholder="Enter 6 digit OTP">
                          </input>
                        </div>

                        <div className='text-base mt-4'>A 6 digit OTP has been send to </div>

                      </div>
                    </div>
                    <div className='contest-play-button'>

                      <div onClick={() => validateOtp(otpState)} className='button play-btn '>VALIDATE</div>

                    </div>

                  <div className='text-black mt-4 flex items-center  justify-center h-[2rem]'>
                          <div className='text-center  px-32 bg-black text-white font-semibold text-base py-[0.35rem] rounded-3xl   mx-auto'>CANCEL</div>
                  </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : <LoaderComponent />
      }
    </>
  )
}

export default Otp