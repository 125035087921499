import React, { createContext, useState } from 'react'
import {txt} from "../components/text"

const Context = createContext()
const {Provider} = Context
const lang = localStorage.getItem("appLang")

function ContextProvider(props) {
    const [isModal , setIsModal] = useState(false)
    const [heModal , setHeModal] = useState(false)
    const [isNewModal , setIsNewModal] = useState(false)
    const [isSubModal , setIsSubModal] = useState(false)
    const [dataState , setDataState] = useState("string")
    const [modalData,setModalData] = useState({
        title : "Oops",
        body : "Something went wrong.",
        btn : ["YES","NO"]
      })
    const [newModalData , setNewModalData] = useState({
        title : "That's all folks",
        body : "You did a great job , next quiz will be available tommorrow",
        btn : ["Go To Leaderboard"]
      })

      const [subModalData , setSubModalData] = useState({
        title : txt.confirmyoursubs[lang],
        body : txt.activatedt[lang],
        btn : [txt.confirmunlock[lang]]
      })
         
      const [endQuesModal,setEndQuesModal] = useState({
        title : "That's all folks",
        body : "You have completed all the questions.",
        btn : ["Unlock More Questions","Check Your Score"]
      })
      const [pageLoader , setPageLoader] = useState(false)

      const toggleModal = (value) => {
        setIsModal(value)
    }
    const toggleHeModal = (value) => {
      setHeModal(value)
  }

    const toggleSubModal = (value) => {
        setIsSubModal(value)
    }

    const toggleNewModal = (value) => {
        setIsNewModal(value)
    }

    const handlePageLoader = (value) => {
        setPageLoader(value)
    }

    const handleModal = (modalData,showModal) => {
        setModalData(modalData);
        setIsModal(showModal);
      }

      const handleNewModal = (modalData,showModal) => {
        setNewModalData(modalData);
        setIsNewModal(showModal);
      }

      const handleSubModal = (modalData,showModal) => {
        setSubModalData(modalData);
        setIsNewModal(showModal);
      }


    const {children} = props

    return (
        <Provider

                value={{
                    isModal,
                    heModal,
                    toggleHeModal,
                    toggleModal,
                    dataState,
                    modalData,
                    handleModal,
                    pageLoader,
                    handlePageLoader,
                    handleNewModal,
                    isNewModal,
                    toggleNewModal,
                    newModalData,
                    toggleSubModal,
                    handleSubModal,
                    isSubModal,
                    subModalData
                }} >

                    {children}

                </Provider>
    )
}

export {Context, ContextProvider}