import React, { useContext, useState } from 'react'
import { Context } from '../Context/Context'
import axios from 'axios'
import { baseUrl, headers } from '../config/config'
import { useNavigate } from 'react-router-dom'
import icon from "./themeimg/dtLite/unlock_questions_icon.png"
import close from "./themeimg/dtLite/close.png"
import { SendGuiDataEvents } from '../CommonScript'
import LoaderComponent from './Loader';


function HeModal(props) {
    const { handlePageLoader,pageLoader} = useContext(Context)
    const modal = props.heModal
    const setModal = props.setModal
    const lang = localStorage.getItem("appLang") || "fr"
    
    console.log("lodader",pageLoader)
    const navigate = useNavigate()
   

    const openHome = ()=>{
      window.location.href = "https://www.google.com";
    }

    return (
      <>
      {pageLoader && <LoaderComponent />}
        <div className={`modal-overlay ${modal ? 'active' : ''} max-w-[500px] right-0 my-0 mx-auto left-0`} >
            <div className='w-[20rem] absolute bg-white top-28 left-0 rounded-xl right-0 mx-auto border-4 border-gray-800 '>
              <div className='float-right' onClick={()=>setModal(false)}>
                {/* <img src={close} className='w-8 h-8'/> */}
              </div>
              <div className="mt-4 w-full" style={{display:'flex',justifyContent:'center'}}>
              <img src={icon} className='h-20 w-20'/>
              </div>
            {/* <div className='text-right text-4xl pr-2' onClick={closeModal}>&times;</div> */}
            <div className='font-bold pt-6 text-center text-2xl'>{lang === "fr" ? "Réseau cellulaire de l'opérateur mobile" : "Mobile Carrier's Cellular Network"}</div>
            <div className='pb-10 pt-3 text-center px-10'>
                <p className='text-xl'>{lang === "fr" ? "Désolé! Il semble que le service ne soit pas disponible pour le réseau cellulaire de votre opérateur mobile" : "Sorry! Seems like service is not available for your mobile carrier cellular network"}</p>
              <div className='flex pt-4'>
                <button className='border-1 text-black bg-[#FFCC00] font-semibold rounded-xl w-[45%] mx-auto p-2' onClick={openHome}>{lang === "fr" ? "d'accord" : "Ok"}</button>
                {/* <button className='border-1 text-black bg-[#FFCC00] font-semibold rounded-xl w-[40%] mx-auto p-2' onClick={handleUnlock}>{btnText2}</button> */}
               </div> 
            </div>
        </div>
        </div>
        </>
        
      )
}

export default HeModal