import React, { useContext } from 'react'
import { Context } from '../Context/Context'
import axios from 'axios'
import { baseUrl, headers } from '../config/config'
import { useNavigate } from 'react-router-dom'
import modalArtImage from "../components/themeimg/assets/modalArt.png"


function ModalNew() {
    const {newModalData , handleNewModal, handlePageLoader, toggleNewModal} = useContext(Context)
    // const navigate = useNavigate()


    const checkSubscriptionApi = async () => {
        // setLoader(true);
        handlePageLoader(true)
        const response = await axios({
            method: 'post',
            url: baseUrl+'checkSub',
            data: {msisdn: localStorage.getItem("msisdn") ? localStorage.getItem("msisdn") : localStorage.getItem("msisdn2")},
            headers: headers,
        })

        handlePageLoader(false)
        if ((response.data.subStatus).toUpperCase() === 'ACTIVE') {
            // navigate('/home')
            window.open('/home',"_self")
        }else if ((response.data.subStatus).toUpperCase() === 'NEW') {
            // navigate('/contest')
            window.open(localStorage.getItem("subUrl"),"_self")
        }

        else {
            // return  window.location.replace(configData.sub_url)
            window.open(localStorage.getItem("subUrl"),"_self")
        }
        // setLoader(false)

    }


  const closeModal = (value) => {
    if (newModalData.btn.length > 1 && value === '1') {
    //   onHandleUnsub(uid)
        console.log("Yes btn clicked")
    } else if (newModalData.btn.length > 1 && value === '2') {
      console.log("No btn clicked")
    } else {
      console.log("Okay btn clicked")
      console.log("newModalData ------->",newModalData)
      if(newModalData.id === 'unsub'){
        // checkSub(uid)
        checkSubscriptionApi()
        console.log("unsub clicked")
      }else if(newModalData.id === 'warning'){
        // window.open(redirect_url,"_self")
        toggleNewModal(false)

    }



    }
    handleNewModal(newModalData,false);
  }


    return (
        <div className='absolute w-full z-100 max-w-[500px] text-white h-full right-0 my-0 mx-auto left-0 bg-green_bg_pic z-60'>
            <div className='w-[95%] absolute bg-modalBg bg-cover bg-no-repeat top-36 left-0 rounded-xl right-0 mx-auto'>
            {/* <div className='text-right text-4xl pr-2' onClick={closeModal}>&times;</div> */}
            <img className='w-40 mx-auto' src={modalArtImage} />
            <div className='font-bold pt-6 text-center text-2xl'>{newModalData.title}</div>
            <div className='pb-10 pt-3 text-center px-10'>
                <p className='text-xl'>{newModalData.body}</p>
                <p className=''>you can check your score on the leaderboard</p>
                <p className='mt-16 py-2 font-bold text-white rounded-full bg-darkGreen border'  onClick={() =>closeModal("1")}>{newModalData.btn[0]}</p>
                {newModalData.btn.length > 1 && <p className='font-bold mt-2 py-2 rounded bg-[#607AF1]  text-white  bg-bgGreenIslam border'  onClick={() =>closeModal("2")}>{newModalData.btn[1]}</p>}
            </div>
        </div>
        </div>

      )
}

export default ModalNew