import React, { useEffect, useState } from 'react'
import logo from "../components/themeimg/INWI_LOGO.png"
import {useNavigate} from 'react-router-dom';
import LoaderComponent from './Loader';
import backArrow from "./themeimg/dtLite/back_menu_arrow.png";

export default function HostedPage() {
    var pageUrl = sessionStorage.getItem("pageUrl");
    var header = sessionStorage.getItem("header");
    const [loader,setLoader] = useState(true);
    const fetchData = async() => {
            await fetch(pageUrl)
            .then((response) => response.text())
            .then((html) => {

            })
            .catch((error) => {
                console.warn(error);
            });
            setLoader(false);
        }
    useEffect(() => {
        fetchData();
    },[])
    const navigate = useNavigate();
    const gotoHome = () => {
        navigate('/home');
    }

    // sticky-fix
    return (
        <>
        <div className='home-page-container'>
            <div className='container theme-bg'>
                <div className='section-home'>
                    <div style={{position:"fixed", top:"0", width:"100%", maxWidth: "500px"}} className='container-nav-bar '>
                        <div className='nav-bar'>
                            <div className='back-arrow'>

                               <div className='back-arrow-button' onClick={() => gotoHome()}>
                               <img src={backArrow} className="h-10 w-10" alt="Back" />
                               </div>
                            </div>

                            <div className='header-hosted-page'>{header}</div>
                            {/* <div className='logo-div'><img className='logo' src={logo} /> </div> */}

                        </div>
                    </div>
                    <div style={{marginTop:"50px"}} className='hosted-page-body'>
                        {
                            loader ? <LoaderComponent/> :    <iframe src={pageUrl}></iframe>
                        }

                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
