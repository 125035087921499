import React from 'react'
import backButton from '../themeimg/assets/back.png'
import { useNavigate } from 'react-router-dom'

function Destination() {
    const navigate = useNavigate()
    return (
        <div className='border-2 border-black h-screen bg-[#F1F1F1]'>
            <div className='border-2 border-black h-20 flex bg-[#E1DAD3]'>
                {/* <div className='border-2 border-black my-auto w-4 ml-4 h-4'></div> */}
                <img className='w-6 my-auto ml-4' onClick={() => navigate(-1) } src={backButton} />
                <div className="my-auto mx-auto font-bold relative right-4 text-3xl">Destination</div>
            </div>

            <div className=' px-2'>
                <div className='w-full mt-4 text-center text-xl font-bold'>
                    About Morocco
                </div>

                <div className='border-2 border-black mt-2 rounded h-[80vh] px-4 text-center py-4 bg-white'>
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                </div>
            </div>

        </div>
    )
}

export default Destination