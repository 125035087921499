let headers = {

    'Servicefor': 'mtn_mu',
    'Username':'web',
    'Language': localStorage.getItem("appLang") ? localStorage.getItem("appLang") : "fr",
    'opco':"evina"
}


let data = {
    "calling_code": "Ghana",
    "msisdn": localStorage.getItem("msisdn") ? localStorage.getItem("msisdn") : localStorage.getItem("msisdn2")

}


const baseUrl = 'https://sandboxapisv1.bngrenew.com/mtnghana_lite/apn/'
const elastic_base_url = "https://serviceusagesv1.bngrenew.com/publish"



export {headers , data , baseUrl,elastic_base_url}