import React from "react";
import { txt } from "./text";


function Error () {

  const lang = localStorage.getItem("appLang")
    const openSplashScreen= () =>{
         window.open("/","_self")
    }
    return(
        // <div className="text-center w-[50%] mx-auto">
        //    <p className="w-[50%] mx-auto border-2 border-black p-10 font-semibold">Unfortunately the site is down for a bit of maintenance right now. 
        //     But soon we will be up and sun will shine again.</p>
        // </div>
        <div className="container ">
              <div className="background-theme">
                <div className='contest-container '>
                
                   
                  </div>
                  <div className='bottom-white-bg bg-[#F8F8F8] bg-cover bg-no-repeat relative pt-12'>
                    
                      <div className= 'card-container has-text-centered  '>
                     

                            <div className='px-2 py-4 bg-white h-[11rem] mt-[2rem] flex text-[#607AF1] items-center bg-no-repeat bg-cover'>
                                   <div className='w-full font-bold text-2xl text-center text-black'>{txt.welcome[lang]}</div>
                            </div>
                          </div>
                       
                        <div className="w-[50%] mx-auto pt-10">
                        <div className='rounded-3xl p-3 text-center cursor-pointer bg-[#481B68] text-white' onClick={openSplashScreen}>{txt.ok[lang]}</div></div>
                    </div>
                    </div>
                    </div>
                    

    )
}

export default Error