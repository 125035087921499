import React, { useContext } from "react";
import backButton from "./themeimg/assets/back.png";
import { useNavigate } from "react-router-dom";
import tickIcon from "./themeimg/assets/tickIcon.png";
import { Context } from "../Context/Context";
import { txt } from "./text";

function UnlockQuestions() {
  const navigate = useNavigate();
  const { toggleSubModal } = useContext(Context);
  const lang = localStorage.getItem("appLang")

  console.log("test commit")

  const gotoHome = () => {
    window.open('/home','_self');
  }
  return (
    <div className=" h-screen bg-[#F3F3F3] max-w-[500px] mx-auto">
      <div className=" h-20 flex bg-white">
        {/* <div className=' my-auto w-4 ml-4 h-4'></div> */}
        {/* <img
          alt="back button"
          className="w-6 my-auto ml-4"
          onClick={() => navigate(-1)}
          src={backButton}
        /> */}
         <div className="pt-6">
             <div className='back-arrow back-btn-quiz' onClick={() => gotoHome()}>
             <div className='back-arrow-button back-btn-align'>
                          <p  className="arrow left "></p>
                        </div>
             </div></div>
        <div className="my-auto mx-auto font-bold relative right-4 text-3xl">
        {txt.unlockquestion[lang]}
        </div>
      </div>

      <div className="mt-2 pt-4 items-center">
        <div className="border-2 mx-auto border-[#9D328D] rounded-xl w-[80%] h-56 shadow-xl bg-white">
          <div className=" mt-4 text-2xl text-center font-semibold font-[#9D328D]">

          </div>

          <div className="flex pt-4">
            {/* <img className="w-[1.5rem] h-full ml-2" src={tickIcon} /> */}
            <div className="text-center text-xl relative right-4 mx-auto">
            {txt.unlockquestion[lang]}
            </div>
          </div>
          <div className=" w-full mt-[1.35rem]">
            <div
              onClick={() => toggleSubModal(true)}
              className=" text-white bg-[#9D328D] w-[50%] rounded-full px-4 py-[0.25rem] mx-auto text-center text-normal cursor-pointer"
            >
              {" "}
              {txt.unlockat5mad[lang]}
            </div>
            <div
              onClick={() => toggleSubModal(true)}
              className=" text-white bg-[#9D328D] w-full px-4 mt-4 py-[0.25rem] mx-auto text-center text-normal"
            >
              {" "}
              {txt.unlockset[lang]}
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default UnlockQuestions;
