import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import headerImage from './themeimg/assets/logo.png'
import oops from "./themeimg/assets/Oops.png"
import { txt } from "./text";
import backArrow from "./themeimg/dtLite/back_menu_arrow.png"
import headerImg from "./themeimg/dtLite/dream_travel_logo.png"
import tick from "./themeimg/dtLite/subscription_activated_icon.png"
import cross from "./themeimg/dtLite/technical_error_icon-1.png"

function UnsubFailModal () {

  const lang= localStorage.getItem("appLang")
  
  const location = useLocation();
  const { title } = location.state || {};
  const { message } = location.state || {};
  const { btn } = location.state || {};
  const { status } = location.state || {};
  console.log("status",status)
  console.log("title",title)

    const gotoHome = () => {
        window.open("/home","_self")
      }

      const openUnsub =()=>{
        window.open("/unsub","_self")
      }

    return(
        <div className="container ">
        <div className="background-theme">
          <div className='contest-container '>
          <div className='upper-yellow-bg bg-[#FFCC00] backdrop-blur-sm pt-4 flex'>
                  <div className='back-arrow back-btn-quiz mt-2' onClick={() => gotoHome()}>
                        <div className='back-arrow-button back-btn-align'>
                         <img src={backArrow} className="h-10 w-10"></img> 
                        </div>
                      </div>
                      <div className="w-[85%] mx-auto ">
                        <img src={headerImg} className="w-[75%] mx-auto"/>
                      </div>
                  </div>
                      <div className='bottom-white-bg bg-[#FAFAFA] bg-cover bg-no-repeat relative pt-12'>
                        <div className="bg-white pt-20 w-[85%] mx-auto h-[95%]">
                            <div>
                            {status === "success" ? <img className="w-28 mx-auto" src={tick} /> : <img src={cross}  className="w-28 mx-auto"></img>}
                            </div>
                            <div className="text-center pt-10">
                                <p className="text-black font-bold text-2xl">{title}</p>
                                <p className="text-[#3B3B3B] font-semibold text-xl">{message}</p>
                                
                            </div>
                            <div className="text-center pt-8 w-[80%] mx-auto cursor-pointer">
                                <button className="bg-[#FFCC00] text-black w-full py-1 text-xl rounded-2xl" onClick={()=> window.open("/home","_self")}>{btn}</button>
                            </div>
                        </div>
                      </div>
        
        </div>
        </div>
        </div>
    )
}

export default UnsubFailModal