import React from "react";
import { Navigate } from "react-router-dom";
import headerImage from './themeimg/assets/logo.png'
import oops from "./themeimg/assets/Oops.png"
import { txt } from "./text";

function UnlockFailModal () {

  const lang = localStorage.getItem("appLang")
      const gotoHome = () => {
        window.open("/home","_self")
      }

      const openUnlock =()=>{
        window.open("/unlock","_self")
      }

    return(
        <div className="container ">
        <div className="background-theme">
          <div className='contest-container '>
        <div className='upper-yellow-bg bg-white flex items-center justify-center backdrop-blur-sm'>
                  <div className='back-arrow back-btn-quiz' onClick={() => gotoHome()}>
                        <div className='back-arrow-button back-btn-align'>
                          <p  className="arrow left "></p>
                        </div>
                      </div>
                      <img className="w-28 relative right-6 mx-auto" src={headerImage} />
                      </div>
                      <div className='bottom-white-bg bg-[#FAFAFA] bg-cover bg-no-repeat relative pt-12'>
                        <div className="bg-white pt-20 w-[85%] mx-auto h-[95%]">
                            <div>
                            <img className="w-32 mx-auto" src={oops} />
                            </div>
                            <div className="text-center pt-10">
                                <p className="text-[#481B68] font-bold text-2xl">{txt.lowbalance[lang]}</p>
                                <p className="text-[#3B3B3B] font-semibold text-xl">{txt.lbmsg[lang]}</p>
                                {/* <p className="text-[#6B4685] text-base">Sorry your unsubscription request is failed</p> */}
                            </div>
                            <div className="text-center pt-8 w-[80%] mx-auto cursor-pointer">
                                <button className="bg-[#9D328D] text-white w-full py-1 text-xl rounded-2xl" onClick={openUnlock}>{txt.tryagain[lang]}</button>
                            </div>
                        </div>
                      </div>
        
        </div>
        </div>
        </div>
    )
}

export default UnlockFailModal