import axios from 'axios'
import React, { useContext, useRef, useState } from 'react'
import { baseUrl, data, headers } from '../config/config';
import editImg from "../components/themeimg/assets/editImage.png"
import { useNavigate } from 'react-router-dom';
import LoaderComponent from './Loader';
import { Context } from '../Context/Context';
import { txt } from './text';
import { SendGuiDataEvents } from '../CommonScript';
import backArrow from "./themeimg/dtLite/back_menu_arrow.png"
import tick from "./themeimg/dtLite/subscription_active_tick_icon.png"
import backgroundImage from "./themeimg/dtLite/page_bg_image.jpg"
import Modal from './Modal';

function Unsub({apiData}) {
    const [inputState, setInputState] = useState()
    const {handleModal , pageLoader , handlePageLoader,toggleModal} = useContext(Context)
    const [modal,setModal] = useState(false)
    const currentUserMsisdn = localStorage.getItem("msisdn") ? localStorage.getItem("msisdn") : localStorage.getItem("msisdn2")
    // const [pageLoader,setPageLoader] = useState(false)
    const handle = useRef(null)
    const navigate = useNavigate()
    const lang = localStorage.getItem("appLang") || "fr"
    const subStatus = localStorage.getItem("subStatus")
    const header = sessionStorage.getItem("header")
    const msisdn = localStorage.getItem("msisdn") ? localStorage.getItem("msisdn") : localStorage.getItem("msisdn2")


    const handleFocus = () => {
        handle.current && handle.current.focus()
      }


      const checkSubscriptionApi = async () => {
        // setLoader(true);
        const response = await axios({
            method: 'post',
            url: baseUrl+'checkSub',
            data: {msisdn: localStorage.getItem("msisdn") ? localStorage.getItem("msisdn") : localStorage.getItem("msisdn2")},
            headers: headers,
        })

        handlePageLoader(false)
        if ((response.data.subStatus).toUpperCase() === 'ACTIVE') {
            navigate('/home')
        }else if ((response.data.subStatus).toUpperCase() === 'NEW') {
            // navigate('/contest')
            window.open(localStorage.getItem("subUrl"),"_self")
        }

        else {
            // return  window.location.replace(configData.sub_url)
            window.open(localStorage.getItem("subUrl"),"_self")
        }
        // setLoader(false)

    }

    


  const unsubReqeustHandler = async() => {

      
    handlePageLoader(true)
    try {
      const resp = await axios({
          url: baseUrl + 'unsubscribe',
          method: 'post',
          data: { msisdn: localStorage.getItem('msisdn') },
          headers: headers
      })

     
      const currentStatus = resp.data.status
        if (resp.data.status === 'failed') {
            
            let guiEvents={};
                  guiEvents['page']="unsub";
                  guiEvents["event"] = "failed";
                  guiEvents["status"] = currentStatus;
                await  SendGuiDataEvents(guiEvents);
            window.open('/unsubfail','_self');

        } else if (resp.data.status === 'success') {
            console.log("api successful")
            let guiEvents={};
            guiEvents['page']="unsub";
            guiEvents["event"] = "success";
            guiEvents["status"] = currentStatus;
            await SendGuiDataEvents(guiEvents);
            window.open('/sub','_self');
        }
        handlePageLoader(false)
    // .finally(() => {
    //   checkSubscriptionApi()
    //   }
    // )
      }
      catch (error) {
        console.error('An error occurred while unsubscribing:', error);
        handlePageLoader(false);
        // You might want to handle errors differently, e.g., show an error message to the user
    }

  }

  const gotoHome = () => {
    window.open('/home','_self');
  }
  const showConfirmPage = ()=>{
    // window.open('/sub','_self');
    unsubReqeustHandler()
    
  }

  const openModal=()=>{
    setModal(true)
  }

    return (
      
        <div className='max-w-[500px] mx-auto  h-[100vh]' style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            {pageLoader && <LoaderComponent />}
            <div className="h-20 flex bg-[#FFCC00]">
            <div className="pt-6">
         <div className='back-arrow back-btn-quiz' onClick={() => gotoHome()}>
         <div className='back-arrow-button back-btn-align'>
         <img src={backArrow}></img>
                    </div>
         </div></div>
        <div className="my-auto mx-auto font-bold relative font-playfair right-4 text-[#0C0C0C] text-3xl">
        {header}
        </div>
        </div>
        {modal &&
            <div>
                 <Modal modal={modal} setModal={setModal} txt1={apiData?.unsub?.canceltxt} txt2={apiData?.unsub?.confirm} btn1={apiData?.unsub?.yes_txt} btn2={apiData?.unsub?.no_txt} />
            </div>
             }
        <div className='border-2 border-black w-[90%] bg-[#FFCC00] mx-auto mt-8 h-[18rem] bg-cover bg-center bg-no-repeat rounded-lg'>
        <div className='flex px-4 pt-6'>
          <div className='font-semibold text-black'>{apiData?.subscription_status}</div>
          <div className='font-semibold text-black'>{apiData?.msisdn}</div>
          </div>
          <div className='flex px-4 pt-2'>
          <div className='flex text-black capitalize'>{subStatus}<img src={tick} className='w-4 h-4 mt-1 ml-1' /></div>
          <div className='text-black'>{msisdn}</div>
          </div>
          <hr className='h-px my-6 bg-black border-0 dark:bg-gray-700'/>
          <div className='flex px-4 pt-2'>
          <div className='font-semibold text-black'>{apiData?.subscription_plan}</div>
          <div className='font-semibold text-black'>{apiData?.validity}</div>
          </div>
          <div className='flex px-4 pt-2'>
          <div className='text-black'>{lang === "fr" ? "Tous les jours" : "Daily"}</div>
          <div className='text-black'>{lang === "fr" ? "1 jour" : "1 Day"}</div>
          </div>
          <div className="pt-2 flex">
                    <button className="buttonunsub bg-black color-[#FFCC00] mx-auto justify-center font-semibold is-fullwidth m-t-10 " onClick={openModal}>{apiData?.unsub?.title}</button>
                </div>
        </div>
      </div>
    )
}

export default Unsub