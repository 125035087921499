import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate, Navigate} from 'react-router-dom';
import { baseUrl, data, headers } from './config/config';
import WelcomePage from './components/WelcomePage';
import Home from './components/Home';
import React,{useContext, useEffect,useState} from "react";
import HostedPage from './components/HostedPage';
import ContestWelcome from './components/ContestWelcome';
import Error from './components/Error'
import Quiz from './components/Quiz';
import Scoreboard from './components/Scoreboard';
import Login from './components/Login';
import Otp from './components/Otp';
import Unsub from './components/Unsub';
import SubPage from './components/SubPage';
import GoToSub from './components/GoToSub'
import OtpPage from './components/OtpPage';
import { Context } from './Context/Context';
import Modal from './components/Modal';
import Layout from './components/section/Layout';
import Destination from './components/section/Destination';
import Kitchen from './components/section/Kitchen';
import TourGuide from './components/section/TourGuide';
import Culture from './components/section/Culture';
import UnlockQuestions from './components/UnlockQuestions';
import ModalNew from './components/ModalNew';
import SubModal from './components/SubModal';
import axios from 'axios';
import Lang from './components/Lang';
import Gallery from './components/Gallery';
import UnsubModal from './components/UnsubModal';
import UnlockModal from './components/UnlockModal'
import UnlockResponse from './components/UnlockResponse'
import QuizModal from './components/QuizModal'
import UnlockFail from './components/UnlockFailModal'
import Activate from './components/Activate';
import Pack from "./components/Pack"
import Thankyou from "./components/ThankYou"
import Subscription from './components/Subscription';

// import scoreboard from './components/scoreboard';


function App() {

  const [apiData,setApiData] = useState([]);
  const [introPageData, setIntroPageData] = useState();
  const [loader, setLoader] = useState(true);
  const {isModal , handlePageLoader,  isNewModal , isSubModal} = useContext(Context);
  const lang = localStorage.getItem("appLang") ? localStorage.getItem("appLang") : "fr";
  console.log("lang::", lang);  
  
  useEffect(()=>{
     if(!lang){
      localStorage.setItem("appLang","fr")
     }
  },[])

  useEffect(() => {
      getConfig();
      // getAvailCont();
  },[])

  console.log("console App.js ----------->")

  // post api for apiConfi
  const getConfig = async () => {
    handlePageLoader(true)
      const response = await axios({
          method: 'post',
          url: baseUrl+'appConfig',
          headers:headers,
      })
      setApiData(response.data)
      const img_Url = response?.data?.drawer_list[1]?.url
      localStorage.setItem("imgUrl",img_Url)
      setIntroPageData(response.data.intro_page[0]);
      localStorage.setItem("thumbUrl" , response.data.resources.thumbURL)
      handlePageLoader(false)
      // setLoader(false);
  }
  console.log("subUrl",apiData.sub_url)





  // useEffect(() => {
  //   if (uid !== '' && uid !== undefined) {
  //     reactLocalStorage.set("uid", uid);
  //     checkSub(uid)
  //   } else {
  //     if (reactLocalStorage.get("uid") !== '' && reactLocalStorage.get("uid") !== undefined) {
  //       checkSub(reactLocalStorage.get("uid"))
  //     } else {
  //       console.log("redirect ---> app.js")

  //       if(directRedirect){
  //         const checkIfMsisdnExists = localStorage.getItem("msisdn")
  //         if(checkIfMsisdnExists){
  //             history.push("/sub")
  //         }else{
  //             history.push("/login")
  //         }
  //       }else{
  //         handleNewUser(true)
  //       }

  //     }
  //   }
  // }, [])




  return (
    <div className="App">

      {isModal && <Modal />}
      {isNewModal && <ModalNew />}
      {isSubModal && <SubModal />}
      
        <Routes>
          <Route exact path='/' element={<WelcomePage baseUrl={baseUrl}  apiData ={apiData} setLoader={setLoader} loader={loader} introPageData={introPageData} headers={headers} data={data} />} />
          <Route exact path='/home' element={<Home baseUrl={baseUrl} configData ={apiData}   headers={headers} data={data} />} />
          <Route exact path="/menuList/:something" element={<HostedPage/>} />
          <Route exact path="/navlist/:something" element={<HostedPage/>} />
          <Route exact path="/contest" element={<ContestWelcome baseUrl={baseUrl} configData ={apiData} headers={headers} data={data} />} />
          <Route exact path="/quiz" element={<Quiz baseUrl={baseUrl} configData ={apiData} headers={headers} data={data} />} />
          <Route exact path="/scoreboard" element={<Scoreboard />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/otp" element={<Otp />} />
          <Route exact path="/unsub" element={<Unsub apiData={apiData}/>} />
          <Route exact path="/sub" element={<GoToSub />} />
          <Route exact path="/sub" element={<SubPage/>} />
          <Route exact path="/otpPage" element={<OtpPage />} />
          <Route exact path="/destination" element={<Destination />} />
          <Route exact path="/kitchen" element={<Kitchen />} />
          <Route exact path="/tourguide" element={<TourGuide />} />
          <Route exact path="/culture" element={<Culture />} />
          <Route exact path="/unlock" element={<UnlockQuestions />} />
          <Route exact path="/lang" element={<Lang/>} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/error" element={<Error />} />
          <Route exact path="/unsubfail" element={<UnsubModal />} />
          <Route exact path="/unlockmodal" element={<UnlockModal apiData={apiData} />} />
          <Route exact path="/modal" element={<Modal apiData={apiData} />} />
          <Route exact path="/unlockres" element={<UnlockResponse />} />
          <Route exact path="/quizmodal" element={<QuizModal apiData={apiData} />} />
          <Route exact path="/unlockfail" element={<UnlockFail />} />
          <Route exact path="/activate" element={<Activate />} />
          <Route exact path="/pack" element={<Pack />} />
          <Route exact path="/thanks" element={<Thankyou/>} />
          <Route exact path="/subs" element={<Subscription/>} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    </div>
  );
}



export default App;
