import React, { useContext } from 'react'
import { Context } from '../Context/Context'
import axios from 'axios'
import { baseUrl, headers } from '../config/config'
import { useNavigate } from 'react-router-dom'
import icon from "./themeimg/dtLite/subscription_cancel_icon.png"
import close from "./themeimg/dtLite/close.png"
import { SendGuiDataEvents } from '../CommonScript'
import LoaderComponent from './Loader';


function Modal(props) {
    const {modalData , handleModal, handlePageLoader, toggleModal,pageLoader} = useContext(Context)
    console.log("lodader",pageLoader)
    const navigate = useNavigate()
    // const unsubData = apiData.unsub
    const handleUnsub = async() => {
    //  toggleModal(false)
      
      handlePageLoader(true)
      try {
        const resp = await axios({
            url: baseUrl + 'unsubscribe',
            method: 'post',
            data: { msisdn: localStorage.getItem("msisdn") ? localStorage.getItem("msisdn") : localStorage.getItem("msisdn2") },
            headers: headers
        })
  
       
        const currentStatus = resp.data.status
          if (resp.data.status === 'failed') {
             
              let guiEvents={};
                    guiEvents['page']="unsub";
                    guiEvents["event"] = "failed";
                    guiEvents["status"] = currentStatus;
                  await  SendGuiDataEvents(guiEvents);
              // window.open('/unsubfail','_self');
              navigate('/unsubfail', {
                state: { title: resp?.data?.title, message: resp?.data?.message, btn:resp?.data?.btn_txt, status:resp.data.status}
              });
  
          } else if (resp.data.status === 'success') {
              console.log("api successful")
              
              let guiEvents={};
              guiEvents['page']="unsub";
              guiEvents["event"] = "success";
              guiEvents["status"] = currentStatus;
              await SendGuiDataEvents(guiEvents);
              // window.open('/sub','_self');
              navigate('/unsubfail', {
                state: { title: resp?.data?.title, message: resp?.data?.message, btn:resp?.data?.btn_txt, status:resp.data.status}
              });
          }
          handlePageLoader(false)
          toggleModal(false)
     
        }
        catch (error) {
          console.error('An error occurred while unsubscribing:', error);
          handlePageLoader(false);
          
      }
  
    }

    return (
      <>
      {pageLoader && <LoaderComponent />}
        <div className={`modal-overlay ${props.modal ? 'active' : ''} max-w-[500px] right-0 my-0 mx-auto left-0`} >
            <div className='w-[20rem] absolute bg-white top-28 left-0 rounded-xl right-0 mx-auto border-4 border-gray-800 '>
              <div className='float-right' onClick={()=>props.setModal(false)}>
                <img src={close} className='w-8 h-8'/>
              </div>
              <div className="mt-4 w-full" style={{display:'flex',justifyContent:'center'}}>
              <img src={icon} className='h-20 w-20'/>
              </div>
            {/* <div className='text-right text-4xl pr-2' onClick={closeModal}>&times;</div> */}
            <div className='font-bold pt-6 text-center text-2xl'>{props.txt1}</div>
            <div className='pb-10 pt-3 text-center px-10'>
                <p className='text-xl'>{props.txt2}</p>
               <div className='flex pt-4'>
                <button className='border-1 bg-black text-[#FFCC00] font-semibold rounded-xl w-[45%] mx-auto p-2' onClick={()=>props.setModal(false)}>{props.btn2}</button>
                <button className='border-1 text-black bg-[#FFCC00] font-semibold rounded-xl w-[45%] mx-auto p-2' onClick={handleUnsub}>{props.btn1}</button>
               </div>
            </div>
        </div>
        </div>
        </>
        
      )
}

export default Modal