import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoaderComponent from './Loader';
import { Context } from "../Context/Context";
import backArrow from "./themeimg/dtLite/back_menu_arrow.png";

function Gallery() {
  const navigate = useNavigate();
  const lang = localStorage.getItem("appLang") || "en";
  const [imgNumber, setImgNumber] = useState(0);
  const [data, setData] = useState(null);
  const [mainImg, setMainImg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { pageLoader, handlePageLoader } = useContext(Context);
  const initialImageIndex = parseInt(localStorage.getItem("lastViewedImageIndex"), 10) || 0;
  const [currentImageIndex, setCurrentImageIndex] = useState(initialImageIndex);
  const header = sessionStorage.getItem("header")

  useEffect(() => {
    const url = localStorage.getItem("imgUrl") || `https:\/\/sandboxapisv1.bngrenew.com\/mtnghana_lite\/r\/html\/gallery.php?lang=en&Servicefor=mtn_mu`;
    handlePageLoader(true);

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        const lastViewedImageIndex = parseInt(localStorage.getItem("lastViewedImageIndex"), 10) || 0;
        const imageUrl = data?.imageUrls[lastViewedImageIndex]?.url || data?.imageUrls[0]?.url;
        setMainImg(`https://sandboxapisv1.bngrenew.com/mtnghana_lite//r//html/${imageUrl}`);
        handlePageLoader(false);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        handlePageLoader(false);
      });
  }, []);

  useEffect(() => {
    if (imgNumber >= data?.imageUrls.length) {
      handlePageLoader(false);
    }
  }, [imgNumber, data]);

  const handleImageClick = (url, index) => {
    if (index !== currentImageIndex) {
      setIsLoading(true);
    }
    setMainImg(`https://sandboxapisv1.bngrenew.com/mtnghana_lite//r//html/${url}`);
    setCurrentImageIndex(index);
    localStorage.setItem("lastViewedImageIndex", index);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleImageLoad = () => {
    setImgNumber((value) => value + 1);
    setIsLoading(false);
  };

  const handlePrevImage = () => {
    setIsLoading(true);
    setCurrentImageIndex((prevIndex) => {
      const newIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
      localStorage.setItem("lastViewedImageIndex", newIndex);
      setMainImg(`https://sandboxapisv1.bngrenew.com/mtnghana_lite//r//html/${data.imageUrls[newIndex].url}`);
      return newIndex;
    });
  };

  const handleNextImage = () => {
    setIsLoading(true);
    setCurrentImageIndex((prevIndex) => {
      const newIndex = prevIndex < data?.imageUrls.length - 1 ? prevIndex + 1 : prevIndex;
      localStorage.setItem("lastViewedImageIndex", newIndex);
      setMainImg(`https://sandboxapisv1.bngrenew.com/mtnghana_lite//r//html/${data.imageUrls[newIndex].url}`);
      return newIndex;
    });
  };

  const gotoHome = () => {
    window.open('/home', '_self');
  };

  return(
    <div className="h-screen max-w-[500px] mx-auto">
      <div className="h-20 flex bg-[#FFCC00]">
        {/* <div className='border-2 border-black my-auto w-4 ml-4 h-4'></div> */}
        {/* <img
          className="w-6 my-auto ml-4"
          onClick={() => navigate(-1)}
          src={backButton}
        /> */}
        <div className="pt-6">
         <div className='back-arrow back-btn-quiz' onClick={() => gotoHome()}>
         <div className='back-arrow-button back-btn-align'>
         <img src={backArrow}></img>
                    </div>
         </div></div>
        <div className="my-auto mx-auto font-bold relative font-playfair right-4 text-[#0C0C0C] text-3xl">
        {header}
        </div>
      </div>
      <div className="ml-2 mr-2 pt-4  text-black ">
        {pageLoader && <LoaderComponent />}
  <div className="w-[90%] mx-auto h-60 relative">
  {isLoading && <LoaderComponent />}
  <div className="bg-black h-8 w-8 rounded-full absolute left-4 top-1/2 transform -translate-y-1/2">
  <div
      className={`absolute left-3 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 text-xl text-white ${
        currentImageIndex === 0 ? "pointer-events-none opacity-50" : ""
      }`}
      onClick={handlePrevImage}
    >&lt;</div></div>
  <img
                    className=" border-2 border-white rounded-lg h-full"
                    src={mainImg}
                    alt=""
                    onLoad={handleImageLoad}
                />
                <div className="bg-black h-8 w-8 rounded-full absolute right-4 top-1/2 transform -translate-y-1/2">
                <div
      className={`absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 text-xl text-white ${
        currentImageIndex === data?.imageUrls.length - 1
          ? "pointer-events-none opacity-50"
          : ""
      }`}
      onClick={handleNextImage}
    >
      &gt;
    </div>

                </div>
  </div>
<div className="" style={{ display: 'flex', justifyContent: 'center', padding: "12px"}}>
    <div className="grid grid-cols-2 gap-x-4 gap-y-4">
        {data?.imageUrls.map((data,index) => (
            <div className="relative w-[11rem] h-[11rem]" key={data.url}>
              
                <img
                    onLoad={() => setImgNumber(value => value + 1)}
                    className="absolute border-2 border-white rounded-lg w-full h-full"
                    src={"https://sandboxapisv1.bngrenew.com/mtnghana_lite//r//html/" + data.url}
                    alt=""
                    onClick={() => handleImageClick(data.url,index)}
                />
                
            </div>
        ))}
    </div>
</div>
      </div>
    </div>
  )

}

export default Gallery;
