import React, { useEffect, useState } from 'react'
import './style/msgAlert.css'

export default function MsgAlert(props) {
    console.log(props)
    const [score,setScore] = useState(0);
    const [message,setMessage] =  useState('');
    
    useEffect(() => {
        setScore(props.checkAnsData ? props.checkAnsData.score : 0);
        // setMessage(props.checkAnsData.message ? props.checkAnsData.message:'');
    },[])
   // const score = props.checkAnsData ? props.checkAnsData.score : 0;


  return (
    <>
    <div className='alert-container'>
        {console.log("if subStatus === success or not", props.checkAnsData.subStatus === "SUCCESS")}
        {console.log("type of subStatus -----> ", typeof props.checkAnsData.subStatus)}
        {console.log("substatus",props.checkAnsData.subStatus,props.checkAnsData.message)}
        <div className={props.checkAnsData.subStatus === "SUCCESS" ? 'alert-box alert-green':'alert-box alert-red'}>
            <div className= 'alert-icon'><i className={props.checkAnsData.subStatus === "SUCCESS" ? 'fa-solid fa-check' : "fa-solid fa-triangle-exclamation"}></i></div>
            <div className='alert-msg'>{props.checkAnsData.message}</div>
        </div>
    </div>
    </>
  )
}
