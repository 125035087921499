import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Context } from '../Context/Context';
import MsgAlert from './MsgAlert';
import LoaderComponent from './Loader';
import { baseUrl, data, headers } from '../config/config';
import { txt } from './text';
import { SendGuiDataEvents } from '../CommonScript';
import backArrow from "./themeimg/dtLite/back_menu_arrow.png";
import headerImg from "./themeimg/dtLite/dream_travel_logo.png";
import './style/quiz.css';
import './style/home.css';

export default function Quiz(props) {
  const location = useLocation();
  const timerIntervalRef = useRef(null);
  const [selectedAns, setSelectedAns] = useState(null);
  const [queData, setQueData] = useState(location.state.nextQue);
  const [availableLevel, setAvailableLevel] = useState(location.state.availableLevel);
  const [radioChecked, setRadioChecked] = useState(false);
  const [checkAnsData, setCheckAnsData] = useState();
  const [alertBox, setAlertBox] = useState(false);
  const [bntLoader, setBtnLoader] = useState(false);
  const [submitBtnShow, setSubmitBtn] = useState(true);
  const [loaderMainScreen, setLoaderMainScreen] = useState(false);
  const [timer, setTimer] = useState(10);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const { toggleNewModal } = useContext(Context);
  const lang = localStorage.getItem("appLang");
  const [score,setScore] = useState(localStorage.getItem("Score"))

  const navigate = useNavigate();
  const gotoHome = () => {
    navigate('/home');
  }

  function optionSelected(key) {
    if (!isOptionSelected) {
      setRadioChecked(true);
      setSelectedAns(key + '');
      setIsOptionSelected(true);
    }
  }

  const getAvailableLevelsForUnlockApi = async () => {
    const response = await axios({
      method: 'post',
      url: baseUrl + 'getAvailableLevelsForUnlock',
      data: { ...data, msisdn: localStorage.getItem("msisdn") ? localStorage.getItem("msisdn") : localStorage.getItem("msisdn2") },
      headers: headers,
    });
    setAvailableLevel(response.data);
  }

  const getNextQuestionApi = async () => {
    setLoaderMainScreen(true);
    const response = await axios({
      method: 'post',
      url: baseUrl + 'getNextQuestion',
      data: { ...data, msisdn: localStorage.getItem("msisdn") ? localStorage.getItem("msisdn") : localStorage.getItem("msisdn2") },
      headers: headers,
    });
    setQueData(response.data);
    if (response.data.subStatus === 'EndOfQuestion') {
     navigate("/quizmodal",{
      state: {
         msg:response.data.msg,
         btn1:response.data.btn1,
         btn2:response.data.btn2,
         unlocktxt1:response.data.unlocktxt1,
         unlocktxt2:response.data.unlocktxt2,
         btn3:response.data.btn3
      }
     });
    }
    setLoaderMainScreen(false);
    setSubmitBtn(true);
  }

  const checkAnswerApi = async () => {
    data['answer'] = selectedAns ? selectedAns : "0";
    data['questionId'] = queData.questionId;
    const response = await axios({
      method: 'post',
      url: baseUrl + 'checkAnswer',
      data: { ...data, msisdn: localStorage.getItem("msisdn") ? localStorage.getItem("msisdn") : localStorage.getItem("msisdn2") },
      headers: headers,
    });
    const status = response.data.subStatus;
    let guiEvents = {};
    guiEvents['page'] = "quiz";
    guiEvents["event"] = "question_played";
    guiEvents["status"] = status;
    SendGuiDataEvents(guiEvents);
    setAlertBox(true);
    setSubmitBtn(false);
    setCheckAnsData(response.data);
    setScore(response.data.score)
    setTimeout(() => {
      getNextQuestionApi();
      setRadioChecked(false);
      setAlertBox(false);
    }, 1700);
  }

  function CheckAns() {
    setLoaderMainScreen(true);
    checkAnswerApi();
  }

  // useEffect(() => {
  //   if (queData && !isOptionSelected) {
  //     setTimer(5);
  //     const timerId = setInterval(() => {
  //       setTimer((t) => {
  //         if (t > 0) {
  //           return t - 1;
  //         } else {
  //           clearInterval(timerId);
  //           return 0;
  //         }
  //       });
  //     }, 1000);
  //     timerIntervalRef.current = timerId;
  //     return () => clearInterval(timerId);
  //   }
  // }, [queData, isOptionSelected]);

  useEffect(() => {
    if (selectedAns) {
      CheckAns();
    }
  }, [selectedAns, isOptionSelected]);

  useEffect(() => {
    if (queData) {
      setIsOptionSelected(false);
      setSelectedAns(null);
    }
  }, [queData]);

  useEffect(() => {
    let guiEvents = {};
    guiEvents['page'] = "quiz";
    guiEvents["event"] = "open";
    SendGuiDataEvents(guiEvents);
  }, [])

  return (
    <>
      {(queData || availableLevel) ?
        <div>
          <div className="container">
            <div className="background-theme">
              <div className='contest-container'>
                <div className='upper-yellow-bg bg-[#FFCC00] backdrop-blur-sm pt-4 flex'>
                  <div className='back-arrow back-btn-quiz mt-2' onClick={() => gotoHome()}>
                    <div className='back-arrow-button back-btn-align'>
                      <img src={backArrow} className="h-10 w-10" alt="Back" />
                    </div>
                  </div>
                  <div className="w-[85%] mx-auto">
                    <img src={headerImg} className="w-[75%] mx-auto" alt="Header" />
                  </div>
                </div>
                {<div className='points-class  bg-black text-center'>
                        <p className="has-text-centered has-text-white font-bold">Score : {score}</p> 
                    </div>}
                <div className='bottom-white-bg bg-[#F8F8F8] bg-cover bg-no-repeat relative pt-12'>
                  <div className={queData.subStatus === 'EndOfQuestion' ? 'card card-height' : 'card px-4 py-4'}>
                    <div className='has-text-centered font-semibold'>{txt.ques[lang]}</div>
                    {/* <div className='has-text-centered'>
                      <div className='timer-container'>
                        <div className='timer'>
                          {timer >= 10 ? `00:${timer}` : `00:0${timer}`}
                        </div>
                      </div>
                    </div> */}
                    <div className='card-container has-text-centered'>
                      {queData.subStatus !== 'EndOfQuestion' && queData &&
                        <div className='question-section'>
                          <div className='question-head font-bold pt-6'>{queData.questionString}</div>
                          <div className='question-options'>
                            {loaderMainScreen && <LoaderComponent />}
                            {queData.optionString.map((option, key) => {
                              const optionClass = selectedAns === (key + 1).toString() ? 'que-option-container selected' : 'que-option-container';
                              return (
                                <div className={optionClass} key={key} onClick={() => optionSelected(key + 1)}>
                                  <label htmlFor={key} className='ques-option-span'>
                                    <div>
                                      <input type='radio' id={key} value={option} name="options" checked={selectedAns === (key + 1).toString() ? radioChecked : false} />&nbsp; {option}
                                    </div>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className='contest-play-button'></div>
                  {alertBox && <MsgAlert checkAnsData={checkAnsData} />}
                </div>
              </div>
            </div>
          </div>
        </div>
        : <LoaderComponent />
      }
    </>
  );
}
