import React, { useContext, useState } from 'react'
import { Context } from '../Context/Context'
import axios from 'axios'
import { baseUrl, headers } from '../config/config'
import { useNavigate } from 'react-router-dom'
import icon from "./themeimg/dtLite/unlock_questions_icon.png"
import close from "./themeimg/dtLite/close.png"
import { SendGuiDataEvents } from '../CommonScript'
import LoaderComponent from './Loader';


function UnlockModal(props) {
    const { handlePageLoader,pageLoader} = useContext(Context)
    const modal = props.modal
    const setModal = props.setModal
    const apidata = props?.apidata?.unlock
    console.log("apidata",apidata)
    const [title,setTitle] = useState(props.txt1)
    const [subTitle,setSubTitle] = useState(props.txt2) 
    const [btnText1,setBtnText1] = useState(props.btn1)
    const [btnText2,setBtnText2] = useState(props.btn2)
    console.log("lodader",pageLoader)
    const navigate = useNavigate()
    const handleUnlock = async() => {
    //  toggleModal(false)
      
      handlePageLoader(true)
      try {
        const resp = await axios({
            url: baseUrl + '/unlock',
            method: 'post',
            data: { msisdn: localStorage.getItem("msisdn") ? localStorage.getItem("msisdn") : localStorage.getItem("msisdn2") },
            headers: headers
        })
  
       
        const currentStatus = resp.data.status
          if (resp.data.status === 'failed') {
             
              let guiEvents={};
                    guiEvents['page']="unlock";
                    guiEvents["event"] = "failed";
                  await  SendGuiDataEvents(guiEvents);
                  navigate('/unlockres', {
                    state: { title: resp?.data?.title, message: resp?.data?.msg, btn:resp?.data?.btnText, status:resp.data.status}
                  });
              
  
          } else if (resp.data.status === 'success') {
              console.log("api successful")
              
              let guiEvents={};
              guiEvents['page']="unlock";
              guiEvents["event"] = "success";
              await SendGuiDataEvents(guiEvents);
              navigate('/unlockres', {
                state: { title: resp?.data?.title, message: resp?.data?.msg, btn:resp?.data?.btnText, status:resp.data.status}
              });
          }
          handlePageLoader(false)
         
     
        }
        catch (error) {
          console.error('An error occurred while unsubscribing:', error);
          handlePageLoader(false);
          
      }
  
    }

    const openHome = ()=>{
      navigate("/home")
    }

    return (
      <>
      {pageLoader && <LoaderComponent />}
        <div className={`modal-overlay ${modal ? 'active' : ''} max-w-[500px] right-0 my-0 mx-auto left-0`} >
            <div className='w-[20rem] absolute bg-white top-28 left-0 rounded-xl right-0 mx-auto border-4 border-gray-800 '>
              <div className='float-right' onClick={()=>setModal(false)}>
                <img src={close} className='w-8 h-8'/>
              </div>
              <div className="mt-4 w-full" style={{display:'flex',justifyContent:'center'}}>
              <img src={icon} className='h-20 w-20'/>
              </div>
            {/* <div className='text-right text-4xl pr-2' onClick={closeModal}>&times;</div> */}
            <div className='font-bold pt-6 text-center text-2xl'>{props.txt1}</div>
            <div className='pb-10 pt-3 text-center px-10'>
                <p className='text-xl'>{props.txt2}</p>
              <div className='flex pt-4'>
                <button className='border-1 bg-black text-[#FFCC00] font-semibold rounded-xl w-[45%] mx-auto p-2' onClick={openHome}>{props.btn1}</button>
                <button className='border-1 text-black bg-[#FFCC00] font-semibold rounded-xl w-[45%] mx-auto p-2' onClick={handleUnlock}>{props.btn2}</button>
               </div> 
            </div>
        </div>
        </div>
        </>
        
      )
}

export default UnlockModal