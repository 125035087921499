import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import headerImage from './themeimg/assets/logo.png'
import modalImg from "./themeimg/dtLite/congratulations_icon.png"
import { baseUrl, data, headers } from '../config/config';
import axios from 'axios';
import LoaderComponent from './Loader';
import ScoreModal from './ScoreModal'
import { txt } from "./text";
import { SendGuiDataEvents } from "../CommonScript";
import backArrow from "./themeimg/dtLite/back_menu_arrow.png";
import headerImg from "./themeimg/dtLite/dream_travel_logo.png";
import UnlockModal from "./UnlockModal";

function QuizModal ({apiData}) {

    const [data,setData] = useState()
    const [loaderMainScreen,setLoaderMainScreen] = useState(false);
    const [score,setScore] = useState()
    const [showScore,setShowScore] = useState(false)
    const lang = localStorage.getItem("appLang")
    const [contestApiData,setContestApiData] = useState();
    const [modal,setModal] = useState()
    const location = useLocation()
    const unlockData = apiData?.unlock
    console.log("apiData",unlockData)
    const { msg, btn1, btn2,unlocktxt1,unlocktxt2,btn3 } = location.state || {};
    console.log("message",msg)
    // const score = localStorage.getItem("Score")

    // const getAvailableLevelsForUnlockApi = async () => {
    //     // data['msisdn']= msisdn;
    //     setLoaderMainScreen(true)
    //     const response = await axios({
    //         method: 'post',
    //         url: baseUrl+'getAvailableLevelsForUnlock',
    //         data: {...data , msisdn: localStorage.getItem('msisdn')},
    //         headers: headers,
    //     })
    //     setData(response.data);
    //     setLoaderMainScreen(false)
    //     console.log("count",response.data.count)
    //     // setScore(response.data.score)
    //     // localStorage.setItem("Score",response.data.score)
    //   }

      const getAvailCont = async () => {

        const response = await axios({
            method: 'post',
            url: baseUrl+'getAvailableContests',
            data: {...data , "msisdn": localStorage.getItem("msisdn") ? localStorage.getItem("msisdn") : localStorage.getItem("msisdn2") },
            headers: headers,
        })

        console.log("response --> ",response.data.contests)
        setContestApiData(response.data.contests)
        console.log("score",response.data.contests[0].score)
        localStorage.setItem("Score",response.data.contests[0].score)
        setScore(response.data.contests[0].score)
        // setPageLoader(false)
    }

    const getAvailableLevelsForUnlockApi = async () => {
      // data['msisdn']= msisdn;
      setLoaderMainScreen(true)
      const response = await axios({
          method: 'post',
          url: baseUrl+'getAvailableLevelsForUnlock',
          data: {...data , msisdn: localStorage.getItem("msisdn") ? localStorage.getItem("msisdn") : localStorage.getItem("msisdn2")},
          headers: headers,
      })
      setData(response.data);
      setLoaderMainScreen(false)
      console.log("data",response.data.status)
      if(response.data.status === 'SUCCESS' ){
          window.open("/unlock","_self")
      }
      else{
          window.open("/unlockmodal","_self")
      }
    }

    const openHome = () => {
        window.open("/home","_self")
      }

      const openModal=()=>{
            setModal(true)
      }

      

      
      useEffect(()=>{
        // getAvailableLevelsForUnlockApi()
        getAvailCont()
        let guiEvents={};
            guiEvents['page']="result";
            guiEvents["event"] = "open";
             SendGuiDataEvents(guiEvents);
      },[])

    return(
        <div className="container ">
             {modal &&
            <div>
                 <UnlockModal modal={modal} setModal={setModal} txt1={unlockData?.unlockquestion} txt2={unlockData?.unlockconfirm} btn1={unlockData?.nothanks} btn2={unlockData?.confirm_msg} />
            </div>
             }
        <div className="background-theme">
        {loaderMainScreen ? <LoaderComponent /> :
          <div className='contest-container '>
           
           <div className='upper-yellow-bg bg-[#FFCC00] backdrop-blur-sm pt-4 flex'>
                  <div className='back-arrow back-btn-quiz mt-2' onClick={openHome}>
                    <div className='back-arrow-button back-btn-align'>
                      <img src={backArrow} className="h-10 w-10" alt="Back" />
                    </div>
                  </div>
                  <div className="w-[85%] mx-auto">
                    <img src={headerImg} className="w-[75%] mx-auto" alt="Header" />
                  </div>
                </div>
                      <div className='bottom-white-bg bg-[#FAFAFA] bg-cover bg-no-repeat relative pt-12'>
                        <div className="bg-white pt-10 w-[85%] mx-auto h-[95%]">
                            <div>
                            <img className="w-32 mx-auto" src={modalImg} />
                            </div>
                            <div className="text-center pt-4 w-[90%] mx-auto">
                                <p className="text-black font-bold text-2xl">{unlockData?.congrats_msg}</p>
                                <p className="text-black font-semibold text-xl">{unlockData?.thanks_msg}
                                </p>
                               
                            </div>
                            <div className="text-center pt-5 w-[80%] mx-auto cursor-pointer">
                                <button className="bg-[#FFCC00] text-black w-full py-1 text-xl rounded-2xl" onClick={openModal}>{unlockData?.unlock}</button>  
                            </div>
                            <div className="text-center pt-1 w-[80%] mx-auto cursor-pointer">
                            <button className=" text-[#FFCC00] border-2 bg-black w-full py-1 text-xl rounded-2xl" onClick={openHome} >{unlockData?.nothanks}</button>
                        </div>
                        </div>
                      </div>
        
        </div>}
        </div>
        </div>
    )
}

export default QuizModal