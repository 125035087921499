import React, { useEffect, useRef } from 'react'
import axios from 'axios'
import { baseUrl, data, headers } from '../config/config';
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'


function OtpPage() {
    const [otpState , setOtpState] = useState("")
    const [styleState , setStyleState] = useState(false)
    // const [numberState , setNumberState] = useState()
    const navigate = useNavigate()

    const {state} = useLocation()



    // useEffect(()=>{
	// 	setNumberState(state.data);
	// }, [])

    const validateOtp = (pin) => {
      axios({
        url: baseUrl+'/activate',
        method:'post',
        data: {"msisdn": String(state.data) , "pin": String(otpState)},
        headers: headers
      }).then((resp) => {
        if(resp.data.status === 'failed'){
          console.log("api failed")
        }else if(resp.data.status === 'success'){
          console.log("api successful")
          navigate('/home')
          // store uid in localstorage
          localStorage.setItem("msisdn",state.data)
        }
      })
    }

    const ResendOTp = (number) => {
        axios({
            url: baseUrl+'/requestOTP',
            method:'post',
            data: {"msisdn": String(number)},
            headers: headers
          }).then((resp) => {
            if(resp.data.status === 'failed'){
              console.log("api failed")
            }else if(resp.data.status === 'success'){
                    setStyleState(true)
              console.log("api successful")
            //   navigate('/otpPage',{state: {data: String(inputState)}})
            //   set a state that would show the message that otp was resent successfully
            }
          })
    }

    const RestrictOtp = (e) => {
        let str = ""
        str += e.target.value
        var thenum = str.replace(/[^0-9.]/g, '').replace(/(\..?)\../g, '$1').replace(/^0[^.]/, '0');
        setOtpState(thenum)

    }



  return (
    <div className='max-w-[500px] mx-auto  h-[100vh]'>
    <div className='bg-subImage w-[90%] mx-auto mt-8 h-[14rem] bg-cover bg-no-repeat '></div>
      <div className='my-2 font-bold mx-auto w-[80%] text-lg text-center'> Trip to your DreamDestination is just a click away! </div>

<div className=''>
    <input
    //   ref={handle}
      disabled="disabled"
      className='block mx-auto text-center bg-[#607AF1] outline-none py-1 rounded-sm w-[70%] text-black font-bold px-4'
      type="number"
      value={state.data}
      placeholder="enter your number"
      onClick={(e) => e.preventDefault()}
    ></input>
</div>
<div className=' text-center mx-auto my-2 text-xs font-bold '> Please enter the Otp </div>

<div className='border-2 border-gray-700 rounded-sm w-[65%] mx-auto'>
    <input
    //   ref={handle}
      className='block mx-auto text-center py-1 outline-none rounded-sm w-[70%] text-black font-bold px-4'
      type="text"
      value={otpState}
      onChange={RestrictOtp}
      maxLength={6}
      placeholder="Enter the pin"
    ></input>
</div>


  <div onClick={() => validateOtp(otpState)} className=' text-white text-center text-sm py-[7px] px-5 font-bold bg-[#607AF1] w-[62%] mx-auto mt-4 rounded-2xl'>
    CONTINUE
  </div>

  <div onClick={() => ResendOTp(state.data)} className='border-2 border-black rounded-sm text-center text-sm py-[5px] px-5  w-[42%] mx-auto mt-4 '>
    Resend
  </div>

  <div className=' text-center text-sm py-[5px] px-5 border-2 border-black rounded-sm w-[42%] mx-auto mt-4 '>
    Back
  </div>

  {styleState && <div className='mt-2 text-center text-[#991b1b] font-bold'>
            otp sent successfully !!
  </div>}


</div>
  )
}

export default OtpPage