import React, { useContext, useState } from "react";
import backgroundImage from "./themeimg/dtLite/page_bg_image.jpg"
import headerImg from "./themeimg/dtLite/dream_travel_logo.png"
import banner from "./themeimg/dtLite/subscription_banner1-min.jpg"
import banner2 from "./themeimg/dtLite/subscription_banner2-min.jpg"
import { baseUrl, data, headers } from '../config/config';
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import { SendGuiDataEvents } from "../CommonScript";
import LoaderComponent from './Loader';
import { Context } from "../Context/Context";

const Pack = ()=>{

    const navigate = useNavigate()
    const lang = localStorage.getItem("lang") || "fr"
    const [okClicked,setOkClicked] = useState(false)
    const location = useLocation()
   

      const handleButtonClick = () => {
        if (okClicked) {
          navigate("/subs")
        } else {
          setOkClicked(true);
        }
      };
    return(
        <div className="max-w-[500px] mx-auto h-[100vh] bg-[#FFCC00]" 
             >
                
            <div><img className="w-[65%] pt-2 mx-auto" src={headerImg} alt="header" /></div>
            <div className="theme-bg bg-cover bg-no-repeat">
                <div className="w-full mt-6 bg-[#FFCC00] relative">
                    <img src={okClicked ? banner2 :banner} className="h-[40vh] w-[100%] mx-auto rounded-xl"></img>
                    <div className="absolute bottom-24 sm:bottom-20 w-full text-center text-white text-2xl">
                      {lang === "fr" ? "Bienvenue chez Voyage de Rêve" : "Welcome to Dream Travel"}
                    </div>
                    <div className="absolute bottom-8 sm:bottom-10 w-full text-center text-white text-3xl font-extrabold">
                    {lang === "fr" ? "Méga Concours Ile Maurice" : "Mega Contest Mauritius"}
                    </div>
                </div>
                <div className="w-[80%]  text-black text-xl ml-8 mt-10 tracking-wide font-extrabold">
                  {okClicked ? (lang === "fr" ? "Pour confirmer la demande" : "To confirm the request") : (lang === "fr" ? "Cliquez sur OK pour vous abonner" : "Click OK to subscribe")}
                </div>
                <div className="w-[80%]  text-black ml-8  mt-6 tracking-wide font-extrabold">
                {lang === "fr" ? "Pack journalier - 100 frs" : "Daily Pack - 100 Frs"} 
                </div>
                <div className="w-[80%]  text-black ml-8 mt-2 tracking-wide text-sm">
                {lang === "fr" ? "*Des micro-frais peuvent s'appliquer" : "*Micro Changes may apply"}
                </div>
                <div className="mt-10 flex">
                    <button className=" border-1 text-[#FFCC00] bg-black font-thin rounded-2xl w-[80%] justify-center mx-auto p-2" onClick={handleButtonClick}>{lang === "fr" ? "D'accord" : "Ok"}</button>
                </div>
            </div>
        </div>
    )
}

export default Pack