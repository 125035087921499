import React,{useState,useEffect, useContext} from 'react';
import './style/welcomePage.css';
import LoaderComponent from './Loader';
import {useLocation, useNavigate} from 'react-router-dom';
import axios from 'axios';
import { baseUrl, data, headers } from '../config/config';
import queryString from 'query-string';
import backgroundImage from "../components/themeimg/dtLite/landing_banner1.jpg"
import headerImage from "../components/themeimg/assets/logo.png"
import { SendGuiDataEvents, sendWapRedirection } from '../CommonScript';
import { Buffer } from "buffer";
import { Context } from "../Context/Context";
import HeModal from './HeModal';



const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}





const WelcomePage = (props) => {
  const headerImg = require("../components/themeimg/dtLite/dream_travel_logo.png")

    const [bntLoader,setBtnLoader] = useState(false)
    const [checksubStatus,setChecksubStatus] = useState('')
    const [msg,setMsg] = useState('')
    const [heModal,setHeModal] = useState(false)
    const [scriptLoaded,setScriptLoaded] = useState(false)
    // const {toggleHeModal,heModal} = useContext(Context)
    // var baseUrl = props.baseUrl
    var apiData = props.apiData;
    const subUrl= apiData.sub_url
    localStorage.setItem("subUrl",apiData.sub_url)
    const heUrl = props.he_url
    localStorage.setItem("heUrl",apiData.he_url)

    var loader = props.loader;
    var setLoader = props.setLoader;
    var introPageData = props.introPageData;
    const navigate = useNavigate();
    const gotoHome = () => {
        navigate('/home');
        setBtnLoader(true);
        // checkSubscriptionApi();
    }


// getting uid

const DecodeBase64 = (str) => {
  if (!str) {
    console.error('DecodeBase64 received an undefined or empty string');
    return '';
  }
  let buffered = Buffer.from(str, "base64");
  return buffered.toString();
};


const ProtectedScript =() =>{
  setLoader(true);
  
  if (document.getElementById("dcbProtect")) {
    console.log("DCBProtect script is already loaded");
    setScriptLoaded(true);
    setLoader(false);
    return;
  }

  axios({
    method: 'post',
    url: baseUrl + "dcbScript",
    headers: headers,
  }).then((resp) => {
    console.log('response', resp.data);
    const scriptContent = resp.data.script;
    if (scriptContent) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.text = scriptContent;
      script.id = "dcbProtect";
      document.body.appendChild(script);
      setScriptLoaded(true);

      // Wait for the DOM to be fully ready
      document.addEventListener("DOMContentLoaded", function() {
        var ctaButton = document.getElementById('cta_button');
        if (ctaButton) {
          console.log("cta_button is ready");
          // Dispatch the custom event after ensuring cta_button exists
          var ev = new Event('DCBProtectRun');
          document.dispatchEvent(ev);
        } else {
          console.error("cta_button not found in DOM.");
        }
      });
    } else {
      console.error("DCBProtect script content is empty");
    }

    let transactionID = resp.data.transaction_identify;
    localStorage.setItem("transactionID", transactionID);

    if (resp.data.status !== "success") {
      navigate('/thanks', {
        state: { message: resp?.data?.message }
      });
    }
    
  }).catch((error) => {
    console.log("error -----> ", error);
  }).finally(() => {
    setLoader(false); // Set loading state to false after API call is completed
  });
}



let location = useLocation();
let params = queryString.parse(location.hash)
const {pageLoader , handlePageLoader} = useContext(Context)
console.log("params", params)
console.log("params.uid", params["/?uid"])
const uid = params["/?uid"]
const pResponse = params["/?p"]
const camp= params["/?camp"]
const rcid= params.rcid

console.log("camp and rcid", camp, rcid);

console.log("camp and rcid",camp,rcid)
// const uid = Object.values(params).toString("")
console.log("uid", uid)
console.log("response", pResponse); 
  const decodedResponse = DecodeBase64(pResponse);
  console.log("decodedResponse", decodedResponse);

  let responseObj;
  try {
    responseObj = JSON.parse(decodedResponse);
  } catch (error) {
    console.error('Error parsing decodedResponse:', error);
  }

  const resStatus = responseObj?.status;
  const resMsisdn = responseObj?.msisdn;
  const rescamp = responseObj?.camp;
  const resrcid = responseObj?.rcid;

  console.log("resstatus", resStatus);
  console.log("resmsisdn", resMsisdn);
  
  if(resMsisdn){
  localStorage.setItem("msisdn",resMsisdn)
  }

  if(rescamp && resrcid){
    localStorage.setItem("camp",rescamp)
    localStorage.setItem("rcid",resrcid)
  }




let query = useQuery();

const userId = query.get("userId")

console.log("userId ---> ", userId)



const checkSubHandler = async (identifier) => {
  setLoader(true)
  try {
    const isMsisdn = identifier === resMsisdn;
      const dataKey = isMsisdn ? { msisdn: identifier } : { uid: identifier };
    const resp = await axios({
      method: 'post',
      url: baseUrl + 'checkSub',
      data: dataKey,
      headers: headers,
    });

    setChecksubStatus(resp.data.subStatus)
    setMsg(resp?.data?.message)
    const currentStatus = resp.data.subStatus;
    if (resp.data.status !== '' && resp.data.status !== undefined) {
      if (resp.data.status === 'failed') {
        navigate('/thanks', {
          state: { message: resp?.data?.message}
        });
      } else if (resp.data.status === 'success') {
         if (resp.data.subStatus === 'active') {
          localStorage.setItem("msisdn2", resp?.data?.msisdn);
          localStorage.setItem("status", resp.data.status);
          localStorage.setItem("subStatus", resp.data.subStatus);

          let guiEvents = {
            page: "subscription",
            status: currentStatus,
            event: "subscribed_user",
            status: currentStatus
          };
          await SendGuiDataEvents(guiEvents);
        
        } 
      }
    }

    setLoader(false)
    localStorage.setItem("subStatus", resp.data.subStatus);
  } catch (error) {
    console.error('An error occurred while checksub:', error);
  }
}

useEffect(()=>{
  if(camp && rcid){
    localStorage.setItem("camp",camp)
    localStorage.setItem("rcid",rcid)
  }
  if(resStatus === "success" && resMsisdn){
    checkSubHandler(resMsisdn)
  }
  if (uid !== '' && uid !== undefined && uid !== null && !pResponse) {
    localStorage.setItem("uid", uid);
    checkSubHandler(uid)
  } 
  if (localStorage.getItem("uid") !== '' && localStorage.getItem("uid") !== undefined && localStorage.getItem("uid") !== null && !pResponse) {
      checkSubHandler(localStorage.getItem("uid"))
  } 
  if(!uid && !localStorage.getItem("uid") && !pResponse)
   {
    console.log("redirected to he")
    if(camp && rcid){
      window.location.replace("http://mtncmr.dreamtravelapp.in/he/index.php?opco='evina'&camp="+camp+"&rcid="+rcid)
    }
    else{
      window.location.replace("http://mtncmr.dreamtravelapp.in/he/index.php?opco='evina'")
    }
  }
  if(resStatus === "failed"){
    // navigate('/thanks', {
    //   state: { message: "Kindly enable your mobile data to access this service"}
    // });
    setLoader(false)
    console.log("status is failed for he")
    setHeModal(true)
  }
  
},[])

useEffect(()=>{
  // ProtectedScript()
},[])



      function onClickHandler() {
        console.log("substatus",checksubStatus)
        setBtnLoader(true);
        if (checksubStatus !== '' && checksubStatus !== undefined) {
            if (checksubStatus === 'new') {
              navigate('/pack',{
                state:{camp:camp,rcid:rcid}
              });
              
            } else if (checksubStatus === 'active') {
              navigate('/home');
            } else if (checksubStatus === 'inactive') {
              
              navigate('/thanks', {
                state: { message: msg}
              });
            } else {
              navigate('/thanks', {
                state: { message: msg}
              });
            }
          
        }
      }

      useEffect(() => {
        let guiEvents={};
    guiEvents['page']="landingPage";
    guiEvents["event"] = "open";
    SendGuiDataEvents(guiEvents);
    },[])




    return (
        <>
            <div>
                <div>
                    <div className="container h-screen">

                    {heModal &&
            <div>
                 <HeModal heModal={heModal}/>
            </div>
             }
                      
                        {loader && <LoaderComponent/>
                        }
                        {/* previous wallpaper for the div below */}
                        {/* style={{backgroundImage:`linear-gradient(rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)),url(${introPageData.img_url})`}} */}
                        {!loader &&  <div className="background-container relative w-full h-full">
          {/* Background Image */}
          <div className="background-image" style={{ backgroundImage: `url(${backgroundImage})` }}></div>

          {/* Header Image on top of Background Image */}
          {/* <div className="header-image-wrapper">
            <img className="header-image w-[65%] mt-2 mx-auto" src={headerImg} alt="header" />
          </div> */}
                            <div className="welcome-page-text has-text-centered absolute bottom-0 mb-2 px-1">
                              
                                <div className="columns text-white">
                                    <h1 className='text-xl font-bold text-left pl-6'>{introPageData?.heading}</h1>
                                    <h5 className="title text-white text-left pl-6">{introPageData?.title}</h5>
                                    <p>{introPageData?.desc}</p>
                                    <a id="cta_button" className="button mx-auto is-warning is-fullwidth m-t-10 " onClick={() => onClickHandler()} >{bntLoader && <div className='loader-btn'></div>}{!bntLoader && apiData?.button_text}</a>
                                </div>
                                </div>
                            
                        </div>}
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default WelcomePage;