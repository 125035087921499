import React,{useState,useEffect} from 'react'
import {Link, Navigate, useNavigate} from 'react-router-dom';
import axios from 'axios';
import { baseUrl, data, headers } from '../config/config';
import LoaderComponent from './Loader';
import { txt } from './text';

export default function MenuBar(props) {
    const [drawerList,setDrawerList] = useState([]);
    const [data,setData] = useState()
    const [langData,setLangData] = useState([])
    const [loaderMainScreen,setLoaderMainScreen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("appLang") || "fr");
    const [showLanguageOptions, setShowLanguageOptions] = useState(false);
    const lang = localStorage.getItem("appLang")
    const msisdn = localStorage.getItem("msisdn") ? localStorage.getItem("msisdn") : localStorage.getItem("msisdn2")
    const navigate = useNavigate()
    useEffect(()=>{
        if (props.drawer_list && props.drawer_list.length > 0) {
            setDrawerList(props.drawer_list);
            if (props.drawer_list[5] && props.drawer_list[5].option) {
              setLangData(props.drawer_list[5].option);
            }
          }
    },[props.drawer_list])


    function hideMenuBar() {
       props.setHamburger(true)
    }

    function  menuListPage(data) {
        sessionStorage.setItem("pageUrl",data.url);
        sessionStorage.setItem("header",data.title);
    }
    


    
    const getAvailableLevelsForUnlockApi = async () => {
        // data['msisdn']= msisdn;
        setLoaderMainScreen(true)
        const response = await axios({
            method: 'post',
            url: baseUrl+'getAvailableLevelsForUnlock',
            data: {...data , msisdn: localStorage.getItem("msisdn") ? localStorage.getItem("msisdn") : localStorage.getItem("msisdn2")},
            headers: headers,
        })
        setData(response.data);
        setLoaderMainScreen(false)
        console.log("data",response.data.status)
        if(response.data.status === 'SUCCESS' ){
            window.open("/unlock","_self")
        }
        else{
            window.open("/unlockmodal","_self")
        }
      }
    

    // const scoreBoardHandler = () => {
    //     navigate("/scoreboard")
    // }

    const unsubHandler = (item) => {
        navigate("/unsub")
        sessionStorage.setItem("header",item.title);
    }

    const unlockQuestionsHandler = () => {
        // navigate("/unlock")
        getAvailableLevelsForUnlockApi()
    }

    const galleryHandler = (item) => {
        navigate("/gallery")
        sessionStorage.setItem("header",item.title);
    }
    function toggleLanguageOptions() {
        setShowLanguageOptions(prevState => !prevState);
    }

    function handleLanguageChange(event) {
        const lang = event.target.value;
        setSelectedLanguage(lang);
        localStorage.setItem("appLang", lang);
        props.setHamburger(true)
        window.location.reload()
    }

    return (
        <div id='mySidenav' className={props.openHam?'menu-bar ':'menu-bar active'}>
               
                <div className='sidebar-heading relative sidebar-heading-title mb-4 bg-white bg-cover h-10  bg-no-repeat'>
                <a href="#" className="close " onClick={() => {hideMenuBar()}}></a>
                        {/* image */}


                            <div className=' h-full w-full flex flex-row items-center justify-center'>
                                    <div className=' w-full text-black pt-2'>  DREAM TRAVEL </div>
                            </div>


                    </div>

                    {loaderMainScreen ? <LoaderComponent/>:
            <div className='link-tags' dir={lang === 'ar' ? 'rtl' : 'ltr'}>
                <div className='mt-6 nav-link-single'>{msisdn}</div>
                <hr className='h-px my-2 bg-[#FFCC00] border-0 dark:bg-gray-700'/>
                {
                  drawerList && drawerList.map((item,ind) => {

                        if(ind === 1){
                            return (
                                <div className='mt-2 cursor-pointer'>
                                <div className='my-2 nav-link-single text-black' key={ind}   onClick={()=> galleryHandler(item)}>{item.title}</div>
                                <hr />
                                </div>
                            )
                           
                        }
                        else if(ind === 2){
                            // return (
                            //     <div className='my-2 cursor-pointer'>
                            //     <div className='my-0 nav-link-single text-white' key={ind}   onClick={()=> unsubHandler()}>{item.title}</div>
                            //     <hr />
                            //     </div>
                            // )
                            return null
                        }else if(ind === 0){
                            // return (
                            //     <div className='cursor-pointer'>
                            //     <div className='mb-2 nav-link-single text-black' key={ind}   onClick={()=> scoreBoardHandler()}>{item.title}</div>
                            //     <hr />
                            //     </div>
                            // )
                        }else if(ind === 3){
                            return (
                                <div className='my-2 cursor-pointer'>
                                <div className='my-2 nav-link-single text-black' key={ind}  onClick={()=> unsubHandler(item)}>{item.title}</div>
                                <hr />
                                </div>
                            )
                        }
                        else if(ind === 5){
                            return (
                                <div className='my-2 cursor-pointer'>
                                <div className='my-2 nav-link-single text-black' key={ind}   onClick={toggleLanguageOptions}>{item.title}</div>
                                <hr />
                                </div>
                            )
                        }
                        else{

                               return ( <div className='my-2 cursor-pointer'>
                                <Link className='my-2 nav-link-single text-black' to={`/navlist/${item.title.replaceAll(" ","")}`} key={ind}   onClick={()=> menuListPage(item)}>{item.title}</Link>
                                <hr />
                                </div>
                                )
                        }
                  })
                }
                 {showLanguageOptions && (
          <div className='language-options flex w-[60%]'>
            {langData &&
              langData.map((item, ind) => (
                <div key={ind}>
                  <input
                    type='radio'
                    id={item.key}
                    name='language'
                    value={item.key}
                    checked={selectedLanguage === item.key}
                    onChange={handleLanguageChange}
                    className='cursor-pointer'
                  />
                  <label htmlFor={item.key}>{item.lang}</label>
                </div>
              ))}
          </div>
        )}

            </div>}

        </div>
    )
}
